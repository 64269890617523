import { ModuleWithProviders, NgModule, PLATFORM_ID } from '@angular/core';
import { O8ConfigService } from '../config';
import { LOGROCKET, O8TrackingModuleOptions, TRACKER_SERVICE } from './tracker.interface';
import { LazyInject, WINDOW } from '../../shared-providers';
import { isPlatformServer } from '@angular/common';
import { TrackerServerService } from './tracker.server.service';
import { TrackerBrowserService } from './tracker.browser.service';
import LogRocket from 'logrocket';
import { TrackerInterceptor } from './tracker.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { isProdHostname } from '@origin8-web/o8-utils/helper';
import { UuidService } from '../uuid';

const trackingFactory =
  (options: Pick<O8TrackingModuleOptions, 'anonymous' | 'withGtm'>) =>
  (
    configService: O8ConfigService<{ trackingId: string; production: boolean }>,
    lazyInject: LazyInject,
    window: Window,
    platformId: object,
    logRocket: typeof LogRocket,
    router: Router,
    uuidService: UuidService,
  ) => {
    if (isPlatformServer(platformId)) {
      return new TrackerServerService();
    }
    /* On local it will be undefined as conf will not be loaded but in production like env (DEV, UAT, PROD) conf is loaded synchronously
      so no problem. On local, logRocket is disabled anyway
    */
    const trackerService = new TrackerBrowserService(lazyInject, logRocket, router, uuidService);
    const enableSessionRecordingBasedOnEnv = isProdHostname(window.location.hostname);
    const opts = {
      ...options,
      enableSessionRecording: enableSessionRecordingBasedOnEnv,
    };
    trackerService.initialize({ ...opts, appId: configService.get$('trackingId') });
    return trackerService;
  };

@NgModule({
  declarations: [],
  exports: [],
})
export class TrackerModule {
  static forRoot(
    opts: Partial<Pick<O8TrackingModuleOptions, 'anonymous' | 'withGtm'>>,
  ): ModuleWithProviders<TrackerModule> {
    const defaultOptions: Pick<O8TrackingModuleOptions, 'anonymous' | 'withGtm'> = {
      anonymous: false,
      withGtm: false,
    };
    const options = { ...defaultOptions, ...opts };

    const baseProviders = [
      {
        provide: LOGROCKET,
        useValue: LogRocket,
      },
      {
        provide: TRACKER_SERVICE,
        useFactory: trackingFactory(options),
        deps: [O8ConfigService, LazyInject, WINDOW, PLATFORM_ID, LOGROCKET, Router, UuidService],
      },
      { provide: HTTP_INTERCEPTORS, useClass: TrackerInterceptor, multi: true },
    ];

    const providers = options.withGtm
      ? [...baseProviders, { provide: 'googleTagManagerId', useValue: 'GTM-NL763RX' }]
      : baseProviders;
    return {
      ngModule: TrackerModule,
      providers,
    };
  }
}
