import { effect, inject } from '@angular/core';
import { getState, signalStoreFeature, withHooks } from '@ngrx/signals';
import { TRACKER_SERVICE } from './tracker.interface';

export function withLogRocketSignalStoreLogger(name: string) {
  return signalStoreFeature(
    withHooks({
      onInit(store, trackerService = inject(TRACKER_SERVICE)) {
        effect(() => {
          const state = getState(store);
          trackerService.track(`${name} state changed`, { state: JSON.stringify(state) });
        });
      },
    }),
  );
}
