export const getRandomUuid = (window: Window, withFallbackPrefix = true) => {
  const getFallbackUuid = () => {
    /* Some browser like the in-app facebook does not support randomUUID in the window object */
    const prefix = withFallbackPrefix ? 'fallback-uuid-' : '';
    return `${prefix}${new Date().getTime()}-${Math.floor(Math.random() * 100001)}`;
  };
  if (!window?.self?.crypto) {
    return getFallbackUuid();
  }
  try {
    return window.self.crypto.randomUUID();
  } catch {
    return getFallbackUuid();
  }
};

export const getRandomUuidFromWindow = () => getRandomUuid(window, false);
